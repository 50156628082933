<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-tabs
            v-model="tabs"
            fixed-tabs
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              href="#tab-perfil"
              class="primary--text"
            >
              Perfil
              <v-icon color="primary"> mdi-account </v-icon>
            </v-tab>
            <v-tab
              href="#tab-empresas"
              class="primary--text"
            >
              Empresas
              <v-icon color="primary"> mdi-office-building </v-icon>
            </v-tab>
            <v-tab
              href="#tab-dashboard"
              class="primary--text"
            >
              Dashboard
              <v-icon color="primary"> mdi-view-dashboard </v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item value="tab-perfil">
              <v-card flat>
                <v-card-text>
                  <perfil-usuario-detalhes
                    class="mb-5"
                    :userInfo="userInfo"
                    :loading="loading"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-empresas">
              <v-card flat>
                <v-card-text>
                  <empresas-liberadas-usuario-data-table
                    class="mb-5"
                    :acessos="userInfo.acessos"
                    :loading="loading"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-dashboard">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <dashboard-usuario
                        class="mb-5"
                        :height="500"
                        :userInfo="userInfo"
                        @dashboard:novo-preferido-configurado="getInfoUser"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
  components: {
    EmpresasLiberadasUsuarioDataTable: () =>
      import('@/components/usuarios/EmpresasLiberadasUsuarioDataTable.vue'),
    PerfilUsuarioDetalhes: () =>
      import('@/components/usuarios/PerfilUsuarioDetalhes.vue'),
    DashboardUsuario: () => import('@/components/usuarios/DashboardUsuario.vue')
  },
  data: () => ({
    tabs: null,
    userInfo: {
      login: '',
      nome: '',
      perfis: [],
      email: '',
      tipo: null,
      cargo: null,
      diretoria: null,
      dashboard: null,
      area_executiva: null,
      acessos: []
    },
    loading: false
  }),
  mounted() {
    this.getInfoUser();
  },
  methods: {
    getInfoUser() {
      this.loading = true;
      AuthService.getUserInfo()
        .then((responseData) => (this.userInfo = responseData))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
